<template>
	<ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Punto de Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Punto de Ventas <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
        v-if="'PuntoVenta Crear' in auth.user.permissions"
          label="Nuevo"
          v-tooltip.top="'Nuevo Cliente'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2 p-button-lg"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtPuntoVenta"
        :value="punto_ventas"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Punto de Ventas"
        responsiveLayout="scroll"
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="id"
          header="ID"
          :sortable="true"
          exportHeader="CÓDIGO"
        >
          {{ data.id }}
        </Column>
        <Column
          field="codigo"
          header="CÓDIGO"
         ></Column>
        <Column
          field="nombre"
          header="NOMBRE/DESCRIPCIÓN"
          exportHeader="NOMBRE/DESCRIPCIÓN"
        >
          {{ data.nombre }}
        </Column>
        <Column field="estado" header="ESTADO">
					<template #body="{ data }">
						<span :class="'product-badge ' + claseEstado[data.estado]">
							{{ data.estado_texto }}
						</span>
					</template>
				</Column>
        <Column field="created_at" header="FECHA DE CREACIÓN">
          <template #body="{ data }">
            <!-- aplico formato de fecha -->
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
      </DataTable>
    </div>
    <PuntoVentaCreate
      :show="puntoventaDialog"
      :puntoventa="puntoventa"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarPuntoVenta"
    >
    </PuntoVentaCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import PuntoVentaService from "@/service/PuntoVentaService";
import { useAuth } from "@/stores";
import PuntoVentaCreate from "@/module/puntoventas/PuntoVentaCreate.vue";

export default {
  components: {
    PuntoVentaCreate,
  },
  data() {
    return {
      punto_ventas: [],
      cargando: true,
      puntoventaDialog: false,
      puntoventa: {},
      buscar: {},
      enviado: false,
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  puntoVentaService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.puntoVentaService = new PuntoVentaService();
    this.initBuscar();
  },
  mounted() {
    this.cargarPuntoVenta();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Cliente Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editPuntoVenta(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "PuntoVenta Eliminar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.deletePuntoVenta(datos.id);
          },
        },
      ];
    },
    cargarPuntoVenta() {
      this.puntoVentaService.getPuntoVentaAll().then((data) => {
        this.punto_ventas = data.punto_ventas;
        this.cargando = false;
      });
    },
    openNuevo() {
      this.puntoventa = {
        estado: { label: "ACTIVO", value: 1 },
      };
      this.puntoventaDialog = true;
    },
    ocultarDialog() {
      this.puntoventaDialog = false;
      this.enviado = false;
    },
    textoEstado (estado) {
      return estado == 1 ? "ACTIVO" : "INACTIVO";
    },
    editPuntoVenta(puntoventa) {
      this.puntoventa = { ...puntoventa,
        estado: { label: this.textoEstado(puntoventa.estado), value: puntoventa.estado },
       };
      this.puntoventaDialog = true;
    },
    deletePuntoVenta(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea eliminar el Punto de Venta?",
				header: "Confirmación",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo eliminar",
				rejectLabel: "No, cancelar",
				accept: () => {
					this.puntoVentaService
						.deletePuntoVentas(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargarPuntoVenta();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        minute: "2-digit",
        hour: "2-digit",
      });
    },
  },
  watch: {
    punto_ventas() {
      this.totalRegistros = this.punto_ventas.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
